import View from './View.js';

class CheckoutView extends View {
  _parentElement = document.querySelector('.main-column');

  _modalWindow = new bootstrap.Modal(
    document.getElementById('modal-two-buttons')
  );
  _modalTitleElement = document.querySelector('.modal-title.two-btn');
  _modalBodyElement = document.querySelector('.modal-body.two-btn');
  _modalYesBtnElement = document.querySelector('.btn-yes.two-btn');

  _modalInputWindow = new bootstrap.Modal(
    document.getElementById('modal-input')
  );
  _modalLabelElement = document.querySelector('.modal-input-label');
  _modalSubmitBtnElement = document.querySelector('.btn-input-modal');
  _modalInputElement = document.getElementById('location-name');

  studentLocation = '';

  showInputModal(label, handler) {
    this._modalLabelElement.innerHTML = label;
    this._modalInputElement.value = '';
    this._modalInputWindow.show();
    this._modalSubmitBtnElement.addEventListener('click', handler);
  }

  hideInputModal() {
    this._modalInputWindow.hide();
  }

  getStudentLocation() {
    return this._modalInputElement.value;
  }

  showModal(title, body, handler) {
    this._modalTitleElement.innerHTML = title;
    this._modalBodyElement.innerHTML = body;
    this._modalWindow.show();
    this._modalYesBtnElement.addEventListener('click', handler);
  }

  addModalListener(handler1, handler2) {
    const modalEl = document.getElementById('modal-two-buttons');
    modalEl.addEventListener('hidden.bs.modal', function (event) {
      const btnEl = document.querySelector('.btn-yes.two-btn');
      btnEl.removeEventListener('click', handler1);
      btnEl.removeEventListener('click', handler2);
    });
  }

  hideModal() {
    this._modalWindow.hide();
  }

  addHandlerButtonPressed(handler) {
    this._parentElement.addEventListener('click', handler);
  }

  _generateMarkup() {
    return this._data.map(this._generateHTMLMarkupString).join('');
  }

  _generateHTMLMarkupString = car => {
    const time = this._generateAMPMTime(car.timestamp);

    return `
      <div class="row student-row border border-top-0" data-docid=${car.docID}>
        <div class="col-1">
          <img
            src=${car.pictureURL}
            class="rounded-circle mt-1"
            width="50px"
            height="50px"
          />
        </div>
        <div class="col-3">
          <h6 class="mt-1 mb-1">${car.firstName} ${car.lastName}</h6>
          <h6 class="fw-light">${time}</h6>
        </div>
        <div class="col-2">
          <h6 class="mt-1 mb-1">${car.grade.substring(3)}</h6>
          <h6 class="fw-light">${car.teacher}</h6>
        </div>
        <div class="col-2">
          <h1 class="d-inline-flex text-primary">${car.carTag}</h1>
          <img
            src="/${car.label === 'No code' ? 'white' : car.label}.png"
            class="rounded-circle code-margin"
            width="30px"
            height="30px"
          />
        </div>
        <div
          class="col-4 d-flex align-items-center justify-content-evenly"
        >
          <button type="button" class="btn-checkout btn btn-primary rounded-pill">
            Check-Out
          </button>
          <button type="button" class="btn-absent btn btn-danger rounded-pill">
            Absent
          </button>
          <button type="button" class="btn-other btn btn-success rounded-pill">
            Other
          </button>
        </div>
      </div>
    `;
  };
}
export default new CheckoutView();
