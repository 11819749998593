import View from './View.js';

class LocationsView extends View {
  _parentElement = document.querySelector('.locations-list');

  _generateMarkup() {
    return this._data.map(this._generateHTMLMarkupString).join('');
  }

  _generateHTMLMarkupString = loc => {
    const time = this._generateAMPMTime(loc.timestamp);

    return `

    <div class="card card-right mb-1">
      <div class="card-body p-1 lh-sm">
          ${time} - ${loc.name}<br>${loc.location}
      </div>
    </div>

    `;
  };
}
export default new LocationsView();
