import { schoolCollectionReference } from './config.js';
import { messagesCollection } from './config.js';
import { usersCollection } from './config.js';
import { carsCollection } from './config.js';
import { tempStudentsCollection } from './config.js';
import { timestampField } from './config.js';

import 'regenerator-runtime/runtime';

let unsubscribe;

export const activateMessagesListener = function (schoolCode, handler) {
  schoolCollectionReference
    .doc(schoolCode)
    .collection(messagesCollection)
    .orderBy(timestampField, 'desc')
    .onSnapshot(querySnapshot => {
      handler(querySnapshot);
    });
};

export const activateLocationListener = function (schoolCode, handler) {
  schoolCollectionReference
    .doc(schoolCode)
    .collection(tempStudentsCollection)
    .orderBy(timestampField, 'desc')
    .onSnapshot(querySnapshot => {
      handler(querySnapshot);
    });
};

export const activateCheckoutListener = function (
  schoolCode,
  carEntry,
  handler,
  gradeFilter = '*',
  teacherFilter = '*'
) {
  if (gradeFilter !== '*') {
    const searchGrade = completeGrade(gradeFilter);
    unsubscribe = schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .where('carEntryNumber', '==', carEntry)
      .where('dismissed', '==', false)
      .where('grade', '==', searchGrade)
      .orderBy('timestamp')
      .onSnapshot(querySnapshot => {
        handler(querySnapshot);
      });
  } else if (teacherFilter !== '*') {
    unsubscribe = schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .where('carEntryNumber', '==', carEntry)
      .where('dismissed', '==', false)
      .where('teacher', '==', teacherFilter)
      .orderBy('timestamp')
      .onSnapshot(querySnapshot => {
        handler(querySnapshot);
      });
  } else {
    unsubscribe = schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .where('carEntryNumber', '==', carEntry)
      .where('dismissed', '==', false)
      .orderBy('timestamp')
      .onSnapshot(querySnapshot => {
        handler(querySnapshot);
      });
  }
};

export const detachListener = function () {
  unsubscribe();
};

export const getStudentName = async function (carDocID, schoolCode) {
  try {
    const carDocument = await schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .doc(carDocID)
      .get();

    return `${carDocument.data().firstName} ${carDocument.data().lastName}`;
  } catch (err) {
    throw err;
  }
};

export const getUserData = async function (userID, schoolCode) {
  try {
    const userDocument = await schoolCollectionReference
      .doc(schoolCode)
      .collection(usersCollection)
      .doc(userID)
      .get();

    return [userDocument.data().username, userDocument.data().carEntryNumber];
  } catch (err) {
    throw err;
  }
};

export const saveMessage = async function (schoolCode, userName, newMessage) {
  try {
    await schoolCollectionReference
      .doc(schoolCode)
      .collection(messagesCollection)
      .add({
        message: newMessage,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
        username: userName,
      });
  } catch (err) {
    throw err;
  }
};

export const confirmCheckout = function (carDocID, schoolCode) {
  try {
    schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .doc(carDocID)
      .update({
        dismissed: true,
      });
  } catch (err) {
    throw err;
  }
};

export const confirmAbsent = function (carDocID, schoolCode, studentName) {
  try {
    schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .doc(carDocID)
      .delete();

    schoolCollectionReference
      .doc(schoolCode)
      .collection(tempStudentsCollection)
      .add({
        location: 'ABSENT',
        name: studentName,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      });
  } catch (err) {
    throw err;
  }
};

export const confirmLocation = function (
  carDocID,
  schoolCode,
  studentName,
  location
) {
  try {
    schoolCollectionReference
      .doc(schoolCode)
      .collection(carsCollection)
      .doc(carDocID)
      .delete();

    schoolCollectionReference
      .doc(schoolCode)
      .collection(tempStudentsCollection)
      .add({
        location: location,
        name: studentName,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      });
  } catch (err) {
    throw err;
  }
};

const completeGrade = gradeFilter => {
  switch (gradeFilter.toLowerCase()) {
    case 'pk3':
      return ',,-PK3';
      break;
    case 'pk4':
      return '..-PK4';
      break;
    case 'pre-k':
      return '00-Pre-K';
      break;
    case 'kindergarten':
      return '01-Kindergarten';
      break;
    case '1st grade':
      return '02-1st Grade';
      break;
    case '2nd grade':
      return '03-2nd Grade';
      break;
    case '3rd grade':
      return '04-3rd Grade';
      break;
    case '4th grade':
      return '05-4th Grade';
      break;
    case '5th grade':
      return '06-5th Grade';
      break;
    case '6th grade':
      return '07-6th Grade';
      break;
    case '7th grade':
      return '08-7th Grade';
      break;
    case '8th grade':
      return '09-8th Grade';
      break;
    case '9th grade':
      return '10-9th Grade';
      break;
    case '10th grade':
      return '11-10th Grade';
      break;
    case '11th grade':
      return '12-11th Grade';
      break;
    case '12th grade':
      return '13-12th Grade';
      break;
    case 'early childhood':
      return '14-Early Childhood';
      break;
    case 'primary':
      return '15-Primary';
      break;
    case 'intermediate':
      return '16-Intermediate';
      break;
    case 'secondary':
      return '17-Secondary';
      break;
    case 'elementary':
      return '18-Elementary';
      break;
    case 'middle':
      return '19-Middle';
      break;
    case 'jr. high':
      return '20-Jr. High';
      break;
    case 'high':
      return '21-High';
      break;
    case 'freshman':
      return '22-Freshman';
      break;
    case 'sophomore':
      return '23-Sophomore';
      break;
    case 'junior':
      return '24-Junior';
      break;
    case 'senior':
      return '25-Senior';
      break;
    case 'sp.ed.':
      return '26-Sp.Ed.';
      break;
    case 'other':
      return '27-Other';
      break;

    default:
      return gradeFilter;
  }
};
