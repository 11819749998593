export const state = {
  user: {
    email: '',
    schoolCode: '',
    name: '',
    uid: '',
    carEntryNumber: 1,
  },
  messages: [],
  cars: [],
  newMessage: '',
  carDocID: '',
  carStudentName: '',
  locations: [],
};

export const updateCarStudentName = function (name) {
  state.carStudentName = name;
};

export const updateCarDocID = function (docID) {
  state.carDocID = docID;
};

export const updateUserState = function (user) {
  state.user.email = user.email;
  state.user.schoolCode = user.displayName;
  state.user.uid = user.uid;
};

export const updateUserData = function (data) {
  state.user.name = data[0];
  state.user.carEntryNumber = data[1];
};

export const updateNewMessage = function (msg) {
  state.newMessage = msg;
};

export const loadMessages = function (querySnapshot) {
  state.messages = [];

  querySnapshot.forEach(doc => {
    const messageObject = {
      message: doc.data().message,
      timestamp: doc.data().timestamp,
      username: doc.data().username,
    };
    state.messages.push(messageObject);
  });
};

export const loadLocations = function (querySnapshot) {
  state.locations = [];

  querySnapshot.forEach(doc => {
    const locationObject = {
      location: doc.data().location,
      name: doc.data().name,
      timestamp: doc.data().timestamp,
    };
    state.locations.push(locationObject);
  });
};

export const loadCheckout = function (querySnapshot) {
  state.cars = [];

  querySnapshot.forEach(doc => {
    const carObject = {
      pictureURL: doc.data().pictureURL,
      firstName: doc.data().firstName,
      lastName: doc.data().lastName,
      timestamp: doc.data().timestamp,
      grade: doc.data().grade,
      teacher: doc.data().teacher,
      carTag: doc.data().carTag,
      label: doc.data().label,
      docID: doc.id,
    };
    state.cars.push(carObject);
  });
};
