class AddMessageView {
  _sendButton = document.querySelector('.send-btn');
  _cancelButton = document.querySelector('.cancel-btn');
  _msgTextArea = document.querySelector('.msg-textarea');

  constructor() {
    this._addHandlerCancelMessage();
  }

  addHandlerAddMessage(handler) {
    this._sendButton.addEventListener('click', handler);
  }

  getMessage() {
    return this._msgTextArea.value;
  }

  clearTextArea() {
    this._msgTextArea.value = '';
  }

  _addHandlerCancelMessage() {
    this._cancelButton.addEventListener('click', this.clearTextArea.bind(this));
  }
}
export default new AddMessageView();
