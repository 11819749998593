import View from './View.js';

class LoginView extends View {
  _loginSection = document.querySelector('.sign-in');
  _loginForm = document.querySelector('.form-signin');
  _signInSpinner = document.querySelector('.sign-in-spinner');
  _emailField = document.getElementById('inputEmail');
  _passwordField = document.getElementById('inputPassword');

  _modalWindow = new bootstrap.Modal(
    document.getElementById('modal-one-button')
  );
  _modalTitleElement = document.querySelector('.modal-title');
  _modalBodyElement = document.querySelector('.modal-body');

  showModal(title, body) {
    this._modalTitleElement.innerHTML = title;
    this._modalBodyElement.innerHTML = body[0] + ': ' + body[1];
    this._modalWindow.show();
  }

  addHandlerLogin(handler) {
    this._loginForm.addEventListener('submit', function (e) {
      e.preventDefault();
      handler();
    });
  }

  toggleSpinner() {
    this._signInSpinner.classList.toggle('hide');
  }

  toggleLoginView() {
    this._loginSection.classList.toggle('hide');
  }

  getCredentials() {
    const email = this._emailField.value.toLowerCase();
    const password = this._passwordField.value;
    this._clearInputs();
    return [email, password];
  }

  _clearInputs() {
    this._emailField.value = '';
    this._passwordField.value = '';
  }
}
export default new LoginView();
