import View from './View.js';

class MessagesView extends View {
  _parentElement = document.querySelector('.messages-list');

  _generateMarkup() {
    return this._data.map(this._generateHTMLMarkupString).join('');
  }

  _generateHTMLMarkupString = msg => {
    const time = this._generateAMPMTime(msg.timestamp);

    return `
      <div class="card mb-1">
        <div class="card-body p-1 lh-sm">
          ${time} - ${msg.username}<br>${msg.message}
        </div>
      </div>
    `;
  };
}
export default new MessagesView();
