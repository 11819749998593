import { auth } from './config.js';
import 'regenerator-runtime/runtime';

export const login = async function (credentials) {
  try {
    const userCredential = await auth.signInWithEmailAndPassword(
      credentials[0],
      credentials[1]
    );
    return userCredential.user;
  } catch (err) {
    throw err;
  }
};
