class HeaderView {
  _emailLabel = document.querySelector('.email-address');
  _filterLabel = document.querySelector('.filter-label');

  renderEmail(email) {
    this._emailLabel.innerHTML = email;
  }

  showFilterLabel() {
    this._filterLabel.classList.remove('hide');
  }

  updateFilterLabel(content) {
    this._filterLabel.innerHTML = content;
    this._filterLabel.classList.contains('filter-label-on')
      ? true
      : this._filterLabel.classList.add('filter-label-on');
  }

  resetFilterLabel() {
    this._filterLabel.innerHTML = 'Filter is OFF';
    this._filterLabel.classList.contains('filter-label-on')
      ? this._filterLabel.classList.remove('filter-label-on')
      : true;
  }
}
export default new HeaderView();
