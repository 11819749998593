import * as model from './model.js';
import loginView from './views/loginView.js';
import mainView from './views/mainView.js';
import headerView from './views/headerView.js';
import messagesView from './views/messagesView.js';
import locationsView from './views/locationsView.js';
import addMessageView from './views/addMessageView.js';
import addFilterView from './views/addFilterView.js';
import checkoutView from './views/checkoutView.js';
import { login } from './auth.js';
import { activateMessagesListener } from './dbHelper.js';
import { activateCheckoutListener } from './dbHelper.js';
import { activateLocationListener } from './dbHelper.js';
import { getUserData } from './dbHelper.js';
import { saveMessage } from './dbHelper.js';
import { getStudentName } from './dbHelper.js';
import { confirmCheckout } from './dbHelper.js';
import { confirmAbsent } from './dbHelper.js';
import { confirmLocation } from './dbHelper.js';
import { detachListener } from './dbHelper.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { async } from 'regenerator-runtime/runtime';

const controlLogin = async function () {
  try {
    loginView.toggleSpinner();
    const credentials = loginView.getCredentials();

    if (!credentials) return;

    model.updateUserState(await login(credentials));

    model.updateUserData(
      await getUserData(model.state.user.uid, model.state.user.schoolCode)
    );

    loginView.toggleSpinner();
    loginView.toggleLoginView();

    controlInitializeMainScreen();
  } catch (err) {
    loginView.toggleSpinner();
    loginView.showModal('ERROR!!!', [err.code, err.message]);
  }
};

const controlInitializeMainScreen = function () {
  mainView.toggleMainView();
  headerView.renderEmail(model.state.user.email);
  headerView.showFilterLabel();

  activateMessagesListener(model.state.user.schoolCode, controlRenderMessages);

  activateCheckoutListener(
    model.state.user.schoolCode,
    model.state.user.carEntryNumber,
    controlRenderCheckout
  );

  activateLocationListener(model.state.user.schoolCode, controlRenderLocations);
};

const controlRenderCheckout = function (querySnapshot) {
  model.loadCheckout(querySnapshot);
  checkoutView.render(model.state.cars);
};

const controlRenderMessages = function (querySnapshot) {
  model.loadMessages(querySnapshot);
  messagesView.render(model.state.messages);
  document.getElementById('new-msg-sound').play();
};

const controlRenderLocations = function (querySnapshot) {
  model.loadLocations(querySnapshot);
  locationsView.render(model.state.locations);
  document.getElementById('new-msg-sound').play();
};

const controlAddMessage = async function () {
  try {
    const newMessage = addMessageView.getMessage();
    addMessageView.clearTextArea();
    if (newMessage.trim() === '') return;
    model.updateNewMessage(newMessage);
    await saveMessage(
      model.state.user.schoolCode,
      model.state.user.name,
      model.state.newMessage
    );
  } catch (err) {
    console.log(err);
  }
};

const controlCheckoutScreenButtonPressed = async e => {
  try {
    const docID = e.target.closest('.student-row').dataset.docid;
    model.updateCarDocID(docID);

    const studentName = await getStudentName(
      model.state.carDocID,
      model.state.user.schoolCode
    );
    model.updateCarStudentName(studentName);

    if (e.target.matches('.btn-checkout')) {
      controlCheckoutStudent();
    } else if (e.target.matches('.btn-absent')) {
      controlAbsentStudent();
    } else if (e.target.matches('.btn-other')) {
      controlLocationStudent();
    }
  } catch (err) {
    console.log(err);
  }
};

const controlLocationStudent = () => {
  try {
    checkoutView.showInputModal(
      `Where is ${model.state.carStudentName} now?`,
      controlConfirmedLocation
    );
  } catch (err) {
    console.log(err);
  }
};

const controlConfirmedLocation = () => {
  checkoutView.hideInputModal();
  const studentLocation = checkoutView.getStudentLocation();

  confirmLocation(
    model.state.carDocID,
    model.state.user.schoolCode,
    model.state.carStudentName,
    studentLocation
  );
};

const controlAbsentStudent = () => {
  try {
    checkoutView.showModal(
      'Please confirm...',
      `Are you sure you want to mark ${model.state.carStudentName} absent?`,
      controlConfirmedAbsent
    );
  } catch (err) {
    console.log(err);
  }
};

const controlConfirmedAbsent = () => {
  checkoutView.hideModal();
  confirmAbsent(
    model.state.carDocID,
    model.state.user.schoolCode,
    model.state.carStudentName
  );
};

const controlCheckoutStudent = () => {
  try {
    checkoutView.showModal(
      'Please confirm...',
      `Are you sure you want to checkout ${model.state.carStudentName}?`,
      controlConfirmedCheckout
    );
  } catch (err) {
    console.log(err);
  }
};

const controlConfirmedCheckout = () => {
  checkoutView.hideModal();
  confirmCheckout(model.state.carDocID, model.state.user.schoolCode);
};

const controlAddFilter = () => {
  const filterParameters = addFilterView.getFilterTypeAndValue();

  if (filterParameters[1].trim() === '') return;

  headerView.updateFilterLabel(`Filter is On: ${filterParameters[1]}`);
  detachListener();

  const gradeFilter =
    filterParameters[0] === 'grade' ? filterParameters[1] : '*';

  const teacherFilter =
    filterParameters[0] === 'teacher' ? filterParameters[1] : '*';

  activateCheckoutListener(
    model.state.user.schoolCode,
    model.state.user.carEntryNumber,
    controlRenderCheckout,
    gradeFilter,
    teacherFilter
  );
};

const controlResetFilter = () => {
  addFilterView.resetTextFields();
  headerView.resetFilterLabel();
  detachListener();
  activateCheckoutListener(
    model.state.user.schoolCode,
    model.state.user.carEntryNumber,
    controlRenderCheckout
  );
};

const init = function () {
  loginView.addHandlerLogin(controlLogin);
  addMessageView.addHandlerAddMessage(controlAddMessage);
  addFilterView.addHandlerAddFilter(controlAddFilter, controlResetFilter);
  checkoutView.addHandlerButtonPressed(controlCheckoutScreenButtonPressed);
  checkoutView.addModalListener(
    controlConfirmedCheckout,
    controlConfirmedAbsent
  );
};
init();
