class AddFilterView {
  _filterButton = document.querySelector('.btn-filter');
  _resetButton = document.querySelector('.reset-filter-btn');
  _cancelButton = document.querySelector('.cancel-filter-btn');

  _gradeFilterText = document.querySelector('.filter-grade-text');
  _teacherFilterText = document.querySelector('.filter-teacher-text');

  _gradeFilterRadio = document.querySelector('.radio-filter-grade');
  _teacherFilterRadio = document.querySelector('.radio-filter-teacher');

  constructor() {
    this._addHandlerCancel();
  }

  addHandlerAddFilter(handler1, handler2) {
    this._filterButton.addEventListener('click', handler1);
    this._resetButton.addEventListener('click', handler2);
  }

  getFilterTypeAndValue() {
    return [
      this._gradeFilterRadio.checked ? 'grade' : 'teacher',
      this._gradeFilterRadio.checked
        ? this._gradeFilterText.value
        : this._teacherFilterText.value,
    ];
  }

  _toggleRadioToGrade() {
    this._gradeFilterRadio.checked = true;
  }

  _toggleRadioToTeacher() {
    this._teacherFilterRadio.checked = true;
  }

  _clearTextArea() {
    this._gradeFilterText.value = '';
    this._teacherFilterText.value = '';
  }

  resetTextFields() {
    this._clearTextArea();
  }

  _addHandlerCancel() {
    this._cancelButton.addEventListener(
      'click',
      this._clearTextArea.bind(this)
    );

    this._gradeFilterRadio.addEventListener(
      'click',
      this._clearTextArea.bind(this)
    );
    this._teacherFilterRadio.addEventListener(
      'click',
      this._clearTextArea.bind(this)
    );

    this._gradeFilterText.addEventListener(
      'click',
      this._clearTextArea.bind(this)
    );
    this._gradeFilterText.addEventListener(
      'click',
      this._toggleRadioToGrade.bind(this)
    );

    this._teacherFilterText.addEventListener(
      'click',
      this._clearTextArea.bind(this)
    );
    this._teacherFilterText.addEventListener(
      'click',
      this._toggleRadioToTeacher.bind(this)
    );
  }
}
export default new AddFilterView();
