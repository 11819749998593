export default class View {
  _data;

  render(data) {
    this._data = data;
    const markup = this._generateMarkup();

    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  _clear() {
    this._parentElement.innerHTML = '';
  }

  _generateAMPMTime(time24HrsFormat) {
    let hours = time24HrsFormat.toDate().getHours();
    const ampm = hours >= 12 ? ' PM' : ' AM';
    hours = hours % 12;
    hours = (hours ? hours : 12).toString();

    const minutes = (
      '00' + time24HrsFormat.toDate().getMinutes().toString()
    ).slice(-2);

    const time = hours + ':' + minutes + ampm;

    return time;
  }
}
