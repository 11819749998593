export const auth = firebase.auth();
export const schoolCollectionReference = firebase
  .firestore()
  .collection('schools');

export const messagesCollection = 'messages';
export const usersCollection = 'users';
export const carsCollection = 'cars';
export const tempStudentsCollection = 'tempStudents'

export const timestampField = 'timestamp';
export const userEmailField = 'userEmail';
